































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DeleteAccount extends Vue {
  get me() {
    return this.$store.state.me;
  }
}
